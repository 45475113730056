.removeClass,
.tagInputClass {
  display: none;
}
.removeTag {
  position: relative;
  font-size: 10px;
  background-color: transparent;
  border-right: none;
  border-color: transparent;
  font-weight: 900;
  color: red;
  cursor: pointer;
}
.inputTag {
  margin-top: 7px;
}

.ReactTags__selected span.ReactTags__tag {
  background: #fff;
  font-size: 14px;
  margin: 0 5px 0 0;
  cursor: pointer !important;
  border-radius: 2px;
  padding: 2px 6px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  display: inline-flex;
}
body {
  font-family: Helvetica, sans-serif;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--focused {
  background-color: #0c7eaf;
  color: #fff;
}

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}

.dancounsell {
  background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/dancounsell/48.jpg);
}

.ladylexy {
  background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/ladylexy/48.jpg);
}

.mtnmissy {
  background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/mtnmissy/48.jpg);
}

.steveodom {
  background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/steveodom/48.jpg);
}

.name {
  margin-left: 68px;
  line-height: 45px;
}

.highlight {
  color: #ee0000;
  font-weight: bold;
}

.react-autosuggest__suggestion--focused .highlight {
  color: #120000;
}

.loader {
	background-color: rgba(0, 0, 0, 0.85);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1990;
}
.loader .loadersvg {
	z-index: 2000;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}


.__JnHV {
  background: transparent !important;
  color: white;
  text-shadow: 1px 1px 25px black;
  margin-left: 50px;
  margin-top: 375px;
  font-size: xx-large;
  font-weight: bold;
  padding: 5px;
  opacity: 1 !important;
  height: min-content;
}
._1OkCh {
  overflow: hidden !important;
  justify-content: left !important;
}
